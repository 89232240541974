debug {
  color: #AA0000;
  &:after {
    content: ')';
  }
  &:before {
    content: '(';
  }
}
.well-thumbs {
  padding: 0;
}

.sticky{
  position: fixed;
  top: 0;
  z-index: 10000;
}


.spin {
    -webkit-animation: spin 2s infinite linear;
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    -webkit-transform-origin: 50% 50%;
    transform-origin:50% 50%;
    -ms-transform-origin:50% 50%; /* IE 9 */
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}