.cart-page {
    margin: 40px 20px;

    .table {
        th, td {
            vertical-align: middle !important;
        }
    }

    form {
        .col-lg-9 {
            border-left: solid lightGray 1px;
        }
        .total h2 {
            padding-top: 0;
            text-align: center;
        }
        .radio, .checkbox {
            border: solid 1px transparent;
            &:hover {
                border: solid 1px #ccc;
                background-color: #f7f4f1;
            }
            .extra {
                display: block;
                margin-left: 20px;
                color: #999;
            }
        }
        .quick-order {
            margin: 7px 20px 7px 20px;
        }
        .btn-checkout {
            margin-top: 15px;
        }
        .action_buttons {
            margin-top: 30px;
            text-align: center;
        }
    }
    .nice-table {
        th {
            text-align: left;
            font-weight: bold;
        }
        input.count {
            width: 50px;
        }
    }
}