
/* common for all forms */
.wheels_search_forms {
    form {
        select.form-control, select {
            padding: 0;
            color: #5e5e5e !important;
            line-height: 18px;
            border-color: #739b42;
            border-radius: 0;
        }
    }
}

.form-search-tyre {
    .form-group {
        margin-bottom: 10px;
    }

    @media (max-width: @screen-xs-max) { // телефоны

    }
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) { // таблетки
        .btn {
            margin: 40px 0 0 10px;
        }
    }
    @media (min-width: @screen-md) and (max-width: @screen-md-max) { // средние десктопы
        .btn {
            margin: 40px 0 0 10px;
        }
    }
    @media (min-width: @screen-lg) { // большие десктопы
        .btn {
            margin: 40px 0 0 0;
        }
    }
}


.form-search-discs-2 {
    .form-group {
        text-align: center;
        border: 1px solid #c7c78f;
        border-radius: 5px;
        padding: 7px;
        margin: 4px;
    }
    label {
        //    margin-bottom: 8px;
    }
    .form-control {
        margin: 0 5px;
        width: 65px;
    }
    .field-type {
        width: 140px;
    }
    .field-manuf {
        width: 200px;
    }

    .search_btn {
        text-align: center;
        .btn {
            width: 150px;
            margin-top: 6px;
        }
    }
}

.search-results {
    .info {
        color: #6d85a5 !important;
        text-align: right;
        margin-right: 10px;
        margin-bottom: 5px;
        .glyphicon {
            font-size: 14px;
        }
    }
}

.search-results-block {
    font-size: 13px;
    margin-top: 5px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    overflow: hidden;
    .search-results-table {
        h3 {
            margin-top: 0;
            padding-top: 0;
            border: 0;
            a {
                text-decoration: none;
            }
            margin-bottom: 6px;
        }
        .item_size {
            font-size: 16px;
            margin-top: 6px;
        }
        .stars {
            .center-block();
            margin-top: 0;
        }
        thead th {
            color: #ffffff;
            white-space: nowrap;
            background: #6d85a5;
            .glyphicon {
                color: #ffffff;
            }
        }
        tbody {
            tr:hover td {
                background-color:#dbe1e9;
            }
        }
    }
}

