.questions-page{
  .detailed {
    margin-left: 70px;
  }
  .answer-icon {
    margin-top: 10px;
    background-color: rgb(146, 213, 138);
  }
  .comments>article{
    padding-bottom: 30px;
  }
}