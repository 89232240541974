form {
    &.ng-dirty {
        input, textarea, select {
            &.ng-dirty.ng-invalid {
                background-color: #fff9f9;
                border: solid 1px #ff606a;
                &:focus {
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(233, 64, 61, 0.60);
                }
            }
            &.ng-dirty.ng-valid {
                background-color: #f6fff4;
                border: solid 1px #2fa146;
                &:focus {
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(127, 233, 141, 0.60);
                }
            }
        }
    }

    .required_field_marker {
        color: #d80000;
        top: -2px;
        left: -2px;
        font-size: 15px;
    }

    .glyphicon-ok {
        color: green;
    }
    .glyphicon-remove {
        color: darkred;
    }

    .form-control {
        &::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #ccc;
        }
        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #ccc;
            opacity: 1;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #ccc;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #ccc;
        }
    }
    .error {
        color: #9C0001;
    }
    .mess_tel_montage{
        color: #737373;
    }
}

.form-add-review {
    h2 {
        margin-top: 0;
    }
    .form-group:last-child {
        margin-bottom: 0;
    }
    .well()
}

