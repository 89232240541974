
.nav-tabs {
    margin: 0;
    border: 0;

    li {
        margin-bottom: 1px;
        a {
            z-index: 20;
        }
    }
    a {
        font-size: 13px;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        border: 1px solid #aaaaaa !important;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        text-decoration: none;
        //background:#FFFFFF!important;
        #gradient > .vertical(@start-color: @btn-default-bg; @end-color: #f1f0ec);
        &:hover {
            #gradient > .vertical(@start-color: lighten(@btn-default-bg, 5%); @end-color: #f1f0ec);
        }
    }
    .active {
        a {

            color: #444444 !important;
            padding-left: 30px;
            background: #FFFFFF url("/images/arrows/down-green.png") 15px 12px no-repeat !important;
            border-bottom-color: #FFFFFF !important;
            padding-right: 15px;
        }
    }
}


.tab-content {
    position: relative;
    top: -2px;
    z-index: 10;
    margin-bottom: 20px;
    background: #FFFFFF;
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    box-shadow: inset -3px -3px 3px #f5f5f2, 1px 1px 0 #e6e4dd;
    overflow: hidden;
    padding: 15px;
    border-radius: 0 5px 5px 5px;

    h2 {
        margin-top: 0;
        padding-top: 0;
    }
}