.fancy-gallery {
  img {
    &:not(.no_border) {
      border: solid #cad2d5 7px;
    }
    margin: 7px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  a.fancybox {
    text-decoration: none;
  }
  /* /fancybox */
}

.figure {
  &.pull-left {
    margin: 0 20px 20px 0;
  }
  img {
    border: #afa5a1 1px solid;
  }
  figcaption {
    font-style: italic;
  }
}

.thumbs-row {
  display: table;
  width: 100%;
  border-spacing: 15px;
  .extra {
    color: gray;
    a:link, a:visited {
      color: #739b42;
    }
  }
  .thumbnail {
    display: table-cell;
    overflow: hidden; // fix collapsing parent element because of child img floating
    float: none;
    vertical-align: bottom;
    margin: 10px;
    width: 50%;
    .img-wrap {
      float: left;
      min-width: 115px;
      text-align: center;
      border: 1px solid #d6d6d6;
      margin: 5px;
      margin-right: 15px;
      padding: 3px;
    }
    @media (max-width: @screen-xs-max) {
      // телефоны
      width: 100%;
      float: left;
    }
    img {
      margin-top: 10px;
    }
    h3 {
      border: 0;
      font-size: 18px;
      text-align: center;
      //      color: red;
    }
    .text {
      clear: both;
    }
  }
}

.thumbnail {
  img {
    border: 0;
  }
  .dop-info {
    min-height: 80px;
  }
  margin-bottom: 20px;
  h3 {
    padding-top: 0px;
    margin-top: 0px;
  }
  .btn {
    margin-top: 7px;
  }
  table.prices {
    width: 100%;
    border-collapse: separate;
    border-spacing: 2px;
    .price {
      //#gradient > .vertical(@start-color: @green; @end-color: lighten(@green, 13%));
    }
    td {
      background-color: #f7f4f1;
      padding: 5px;
    }
  }
}


.bonus_img {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -15px;
  left: -15px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  &.bonus_small {
    width: 50px;
    height: 50px;
    top: -10px;
    left: -10px;
  }

  &.bonus_red {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAMAAAANf8AYAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADYUExURUxpcaAnJ6suLsc8PKQpKZ0lJaMlJZ0hIZ0XF6UoKJ4mJp8mJqwtLZwlJZ8mJp4lJcg+Psg+Psk+PqcrK6QpKa0uLr44OKotLcc9Pbk1NcA5OcU8PL03N7UzM54mJqAnJ50lJaIoKLAwMLg0NLMxMa0vL6gsLL44OKstLcQ7O8E6OrYzM8o/P7s2NqQpKaYqKsc9Pf7+/rw3N/fq6qw3N9eenrFCQuC3t8p/f/Db2+3T086KitOUlP37+7ZNTfv19fPj47xdXcFra+XAwMZ2dt2urrhVVejJyZdqtcgAAAAedFJOUwCMY01M3RcMBSf2yTXqspnepI28etGz57umdO3Q8l0oy6kAAAMRSURBVEjHldbrdqIwEABgVBC8t1btHUO3pra1pNkE5Cp4f/832iFYF1tEO0d/wXcymWECknQkKtJvQr2sSVJ30gV3UTvjfg3+l5NJtzv5/LwcvL4+aFKtfIJc3JQrE4hPiFeIt8HNy0uz0Ij7M+Lt7QXidpdAzjYqzerNDmTF34+P24fHu4I1csSfJG5zzV1GvKXi75d4f7/Or3CBeH5+7vevvtXi5v6H+EjFuxBPSciH5uK0GI1yzAkxGpeq2VZWLnJKJcB/kUR/v1T3/jyBMd6byyIxygiM99mVTwnbcucACLlWv0wzpx3ZNThz/RkIiOueUNrd/Q/x9F/MV9ihO0GIaXYSMzhe3DH81my0oOsUmRDiYagcFWPLw3hOF3hJKQuEMK7S7TzkC2c19ukGYzYlZON71AdhGA1ROW2Q0w4bknKxHVqEWBGHrIi3NUQokJw6yBExm0FSG7JlmMyoA0nxeC3IcAjZ3X4vbrKROGQ2ji2yogtiulNz40WuLcSwDkdR7TErngIWLm1ssWVsBwzzaGqa063BgwUWYjgUp5CcbaAVOU7kjmZ0sXShYqZFfSfyd1mJKCWm9ijELFgGo3FkYWihT7w133qQFJ7S2M+I4VCHx1TrizXW1PWowymYMXOJH3Hu0YgbhnkodF3RpF5aKivk43iN2RbabVGbhyuTu97sACRCV6AG6pWoFFSUxws8DW0C1dqYS2gH/il0Je1pSUyHx1jInDldE+JQbs5XRo7Q9Xp66vfEPPmw0DTka2o5bJq23PgOkhDPtXadDCCxw4As6BxaEwf4qNBRKxkgWUwgIct44zEuHsTjAnXS6Zb7YpxWlC7tEwL19mdVO5knHJwUCLX3piHm6bRAqLU/3vNAnkD19tfBU72qFwm0j7KafZHIZwiEqodvnzMEQodvfrVwG7vNKIdGazca9UKhqHlvx06ByHYmG+UCgVAp19R0pa3kila1JteOfuyUf7HGnrWQ0jm8X+60ij94JBmul6BK8EeNckuUSzvng6zR0yS53lAltdT81adfUz125R8hAw8Bvwi1kAAAAABJRU5ErkJggg==);
  }

  &.bonus_green {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAMAAAANf8AYAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADSUExURUxpcVObEVGVElCTElWkDlKXElupE0SPAV2uEl2tEGS5FFKWE1yqFFKVE2S7FWK3E1iiFGK3FGO5FU+QEV2rFFWdE2G0FFyrFFyqE2S6FGS6FVSbE12rFF6uFFaeFFWcFFegFF+wFFOYE2K1FVKWE2K3FVGUE1uoFGS6FViiFGCxFGCyFFCSE/7+/WW8FVSaE1qmFFSZE1mkFGG0FWO4FU+QE22xL6zSiPb68eTw2ZXHZ7zboGWqJNLnv6LNet3tzoK9S4zBW7PWk3i3Pu315MjhsMd+zvgAAAAcdFJOUwAwl8YUcVcFQCG14eCv3HbG8fhK8YTPna+S5PLuhA5sAAADEUlEQVRIx5WW6ULqMBCFCxRoKfvqUq4iaAEdjFG67y3v/0o3SaUCBornL3w9M2eyCcIZDerCH1QvlwShum3VBUmRrvm/QP++LVe3b2+V8suLIgpitcCxUqkOtkRvRC9UldZiUbuIlLZ7ICMWRE9Ple8COGUNSnKZQxC1KvdlLiNzPYg+Pt7f37v8cA+JxRHxQMQrrj66RDz8U7rdwTHRUi4TVI/Vk4yP2lhkwDHxeMq0LhOPVKuJfDSXSjFB1czHW1VOw+UTGOO8vNHvcRy2kRGYCpX2jFRIJI6lEwKhvCOxyCMxA19HVMORyJCawh9HBmA7xI7JAI3qhiLli8QKpTtsgKFnhKax5ErnCdfyMY7BdS2A1KeE2szWXYsfru642LIwSsBHamhY4GuqqjazFMocwiUmYJOiEoSCgDmknko1lOiJwfFwIMbY85AODtJ8SAijmw5FplNS3YgXrgFg4Ah05JmqppOq7MAMdEpMp8RHVI6IyAIrwuT7pqGahmaDrWlmrOpGNGXEbMRyuz/wiCAmVEjqisB3Uk21UjuGMKuKELMZm4+oMML1d7G78iyMddNCNugR7CDUEguC6IeYzdYdGjXziMHySONBQJYV/TCrC3YsKgZ8E+vXnijUsgH6oK+8AO/ARWQihkbq0ux9VAfEa49lzaLSIUaegXywySIBRwtpE9NfxGcv2w1Vtj08M4XUcc3U1Vzioxr6kceaEUTZqV9jG8qG2DUgsiGwPVM/6SMnPucNxnTZDlTNHRk76dxKd8lZYj7v0wVXwmwHIsdMaLia+uORt/H6DcznjWyNSl22oUKAICwgnif5UTVhG8pIiojnr3HO3LAdqHIGmLfBiK/lUtwzzWuJfmN/8NRvhlcRy454eJFIw7PhEiAjlsuTW7xZTGw2J5dxs5jot098Jt3xkBduTmxE3oXaOUdsqNrcS1i6QGw2Df4DYd1ujPmELEuls48d6XAcBR65xvO72/4h0ZBu7y4/eARJqgud5fKu0ydt19qbnnjlC65xKwpSry0L9U7tLy8/QZbP/fIffJ4XkWwudYEAAAAASUVORK5CYII=);
  }

  &.bonus_orange {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAMAAAANf8AYAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAB1UExURUxpcfmQCfaPCvqQBvuQBfaOBvyQBPqQB/mQB/SMA9p4APqQBvmPBvqQCP6QAqR1APiRCvuQBf+QAfuQBvqQCP2QA/aRDPiRCv7+/vOREP3Ig/WRDf20V/7u2f7Smf2aG/ygKv7etfypPv726/2/b/7nyv78+Hm6XZsAAAASdFJOUwDWN5uFK/jCXBcGb0fu5QLnrxLdMOEAAAKcSURBVEjHlZbptqsgDIXFCceeVmhFyyBO7/+IN2CPHQ5q7/7Rdq3mM8kmAp63IT+Djx/vW4WF5xUxTr0IJ1+EmwcnVXXJ46pCYVzFkZfm6T4ToNA/VU9d0SW+hrtIGldvulqdoLXMDRR+mjsIEMYldiIZqdyEVem0MNpDrtfI5ViyR0BTCOcfS3K67hJGJPjw+Jggn0x4gBAj/NZTdDlMYnVelzdB3xGv5SXHZf1qndn0MEknhbI/inUCTgdl9fM0dvBNaRwsQ16gXYL3RM7EEkbIIPlBI4MmLWs7+lC+zP9mEiVGAkmUEoyJ0SLxUlvoJpRURAjon42E9q1gFip9y+QOAlxS8whF9YRMkwmuhV4SJRuIZC0heiKKSUJGZprpBrk0VKZe4mqkZQBx1hE9UKqgKj7Nk1oYyJPhN4ALJrh5/tzSwXCcwjftWv7w7WJ9O70irOVi7onWfB6lIFQMXLKePoXNbpIhG61GLSnRwjQvTF18NsGdYBOnrwpWq+UsNDQ8Tbab3tTDZ2baJvRddeT5xDKtaXgiegaXezBACgqQpJ+qrdehhRTETS34CitPILY3TdR/iLpeJjuJjb96GNgg1SBqcK2l9Dlhb0i9vEGRnUZwTLWMczaNenDEL0RdI7sLB3Z8KcxvZ1ZSDLrbJG63e2Hnepl5Ofd67umGHsQN+49NZ3kZGZv6PcIi61aVW6jtjojb/bnVh+T57u4RIP+XwdvEO9LglQnKr4h78Hqy/vjomGiaj1Mcf4E0+RHzh2jQR54Qo3Kf8F0HauJY9pVoGudpn+4kgcLcF4TzGWM3UUS+72YyM7D/kWNd3roMzq8I9kOU7zNRknnF7VaG5/u9THDTRF9e48IL3N1QCeFJ/u2lzz43jbb+/gekIpjH/qHx7gAAAABJRU5ErkJggg==);
  }
}

