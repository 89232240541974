@media (max-width: @screen-md){
  li.sub-menu {
    ul {
      height: 0 !important;
      display: none !important;
    }
  }
}
@media (min-width: @screen-md){
  // средние десктопы
  // большие десктопы

  @background_color_1: #0375b8;

  /*!* Sub menus *!*/
  /* Third-level menus */
  /* Fourth-level menus */
  #nav {
    li.sub-menu {
      ul {
        display: none;
        visibility: hidden;
        position: absolute;
        top: 5px;
        left: +99%;
        width: max-content;
        width: -moz-max-content;
        ul {
          height: auto;
          left: 170px;
          display: none;
          visibility: hidden;
          width: max-content;
          width: -moz-max-content;
          ul {
            left: 170px;
            display: none;
            visibility: hidden;
            width: max-content;
            width: -moz-max-content;
          }
        }
        li {
          display: block;
          visibility: visible;
          background-color: @background_color_1;
          padding: 0 15px;
          width: auto;
          a {
            padding: 8px 5px;
            line-height: 1.428571429;
          }
        }
      }
      li {
        &:hover {
          >ul {
            display: block;
            visibility: visible;
          }
        }
      }
    }
  }

}