/*! including bootstrap */
@import '../../bower_components/bootstrap/less/bootstrap.less';
/*! autobam styles */
@import 'less/mixins';
@import 'less/layout';
@import 'less/typography';
@import 'less/forms';
@import 'less/buttons';
@import 'less/left_menu';
@import 'less/modals';
@import 'less/helpers';
@import 'less/images';
@import 'less/cart-mini';
@import 'less/old_autobam_styles';
@import 'less/vertical-tabs';
@import 'less/livesearch';
@import 'less/media';
@import 'less/tabs';
@import 'less/table';
@import 'less/pagination';
@import 'less/top-menu-sub';

@import "less/distribute";

// PAGES
@import 'pages/index';
@import 'pages/search_by_car';
@import 'pages/wheels_search';
@import 'pages/our-works';
@import 'pages/questions';
@import 'pages/contacts';
@import 'pages/cart-page';
@import 'pages/tyres';
@import 'pages/services';
@import 'pages/comments';

@import 'pages/all';

@import "less/slick";
@import "less/slick-theme";

@bgColor: #f7f4f1;
@green: #739b42;
@green_00CC33: #00CC33;
@red_CC0000: #CC0000;
@red_FF0000: #FF0000;
@red_FF6600: #FF6600;

.hide-compatibility{
  display: none;
}

.warning{
  color: #aa0000 !important;
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.f-wrap {
  flex-wrap: wrap;
}
.h-auto {
  height: auto!important;
  transition: 0.3s!important;
}



body{
  overflow-y: scroll;
}

input[type="radio"],
input[type="checkbox"] {
  top: 2px;
}

[contenteditable="true"]{
  border: 1px red dashed;
}

// заплатка для текстов со старого сайта
.yellow-color{
  font-weight: bold;
}

div[data-gaexperiment],
li[data-gaexperiment]{
  display: none;
}

#webcam-buttons .btn{
  float: none;
  margin: 0;
}

/* **** Просто шаблончики для разных размеров экрана - копировать в нужные места. Здесь не изменять!  *** */

@media (max-width: @screen-xs-max) {
    // телефоны
}

@media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    // таблетки
}

@media (min-width: @screen-md) and (max-width: @screen-md-max) {
    // средние десктопы
}

@media (min-width: @screen-lg) {
    // большие десктопы
}


