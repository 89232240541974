.livesearch {
    .highlight {
        color: #c60800;
    }
    .angucomplete-dropdown {
        overflow-y: auto;
        max-height: 500px; // your preference
        text-shadow: none;
        width: 450px;
        //left: -250px;
        top: 40px;
        box-shadow: 2px 2px 3px 0px rgba(140,140,140,1);
        border: 1px solid gray;
        //float: right;
        font-size: 15px;
        h3 {
            margin-top: 3px;
        }
        .alert {
            padding: 5px;
            font-size: 13px;
            line-height: 18px;
        }
        .angucomplete-title {
            color: #6b809c;
        }
        .angucomplete-description {
            color: gray;
            margin-left: 65px;
            margin-top: 4px;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .smallo-tron {
        .angucomplete-dropdown {
            left: auto;
            top: 38px;
        }
    }

}
