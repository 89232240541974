.tyres-best-models, .tyres-by_manuf {
    .thumbnail {
        img {
            margin-top: 0;
        }
        h3 {
            text-align: left;
        }
    }
    .comments {
        display: block;
        color: #739b42 !important;
    }
    .btn {
        float: right;
        margin: 20px 15px 0 0;
    }
}


.tyre_size .item {
    box-shadow: inset -3px -3px 3px #f5f5f2, 1px 1px 0 #e6e4dd;
    padding: 15px;

    .store.in li {
        /* есть в наличии */
        display: block; // показать все
        &.instore {
            // покрасить надписать "В наличии"
            font-size: 18px;
            height: 24px;
            color: #739b42;
            margin: 0;
        }
    }
    .store li {
        /* нет в наличии */
        display: none; // скрыть все
        &.instore {
            // кроме надписи "нет в наличии"
            display: block;
            font-size: 16px;
            color: #c62127;
            margin: 20px 0 0 10px;
            span {
                color: #555;
                font-size: 14px;
            }
        }
    }
    .col-bordered {
        border-left: 1px solid #fcfcfb;
        border-right: 1px solid #dbd9cf;
        &:last-child {
            border-right: 0;
        }
        &:first-child {
            border-left: 0;
        }
        min-height: 160px;

        /* just template */
        @media (max-width: @screen-xs-max) {
            // телефоны
            border: 0;
            border-top: 1px solid #fcfcfb;
            border-bottom: 1px solid #dbd9cf;
            &:last-child {
                border-bottom: 0;
            }

            &:first-child {
                border-top: 0;
            }

            min-height: 0;
            .fancybox {
                display: block;
                padding-bottom: 20px;
            }
        }
    }
    ul {
        list-style: none;
        padding-left: 0;
        line-height: 20px;
    }
    .common-info {

    }
    .price {

        font-size: 18px;
        color: #739b42 !important;
        padding-top: 5px;
    }

    .price-additional {

	  margin-left: 54px;
    }
}



.availability-indicator{

  display: inline-block;
  width: 135px;

  span{

	&.label{
	  padding-left: 0.4em;
	  margin: 0 0.1em 0 0;
	  border: 1px #6d85a5 solid;
	}

	&.label:last-child{
	  margin: 0;
	}


	&.label-info{
	  background-color: #6d85a5;
	}
  }

}

.tyres-list_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    background: #FFF;
    border: 1px solid #aaa;
    padding: 15px 15px 0px 15px;
    border-radius: 5px;

    .tyres-list_block {
        display: flex;
        padding: 4px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-bottom: 20px;
        width: 100%;
    }

    .tyres-list_img-wrapper {
        min-width: 115px;
        text-align: center;
        border: 1px solid #d6d6d6;
        margin: 5px 15px 5px 5px;
        padding: 3px;
    }
    .tyres-list_img {
        max-width: 115px;
    }
    .tyres-list_buttons-wrapper {
        width: 124px;
        text-align: center;
    }

    .tyres-list_content-wrapper {
        margin: 5px 5px 5px 0px;
        color: #333;
    }

    .tyres-list_title {
        display: block;
        font-family: MPC;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .tyres-list_property {
        margin-bottom: 0px;
    }

    .tyres-list_price {
        margin-top: 8px;
    }
}

