.modal, .panel {
  .main-text-style();
  .modal-header {
    background-color: #4b5f7a;
    padding: 10px 20px;
    h1, h2, h3, h4 {
      border-bottom: 0;
      padding-top: 0;
      font-size: 24px;
      color: white;
    }
  }
}
