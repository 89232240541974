.btn {
    box-shadow: 2px 2px 3px 0 rgba(30,30,30,.95);
}

.btn, .btn:hover {
    text-decoration: none;
}

.btn-success {
    #gradient > .vertical(@start-color: @green; @end-color: lighten(@green, 13%));
    &:hover {
        #gradient > .vertical(@start-color: @green; @end-color: lighten(@green, 23%));
        //    text-decoration: underline;
    }
}

.btn-success-00CC33{
    #gradient > .vertical(@start-color: @green_00CC33; @end-color: lighten(@green_00CC33, 13%));
    &:hover {
        #gradient > .vertical(@start-color: @green_00CC33; @end-color: lighten(@green_00CC33, 23%));
        //    text-decoration: underline;
    }
}

.btn-danger-FF0000{
    #gradient > .vertical(@start-color: @red_FF0000; @end-color: lighten(@red_FF0000, 13%));
    &:hover {
        #gradient > .vertical(@start-color: @red_FF0000; @end-color: lighten(@red_FF0000, 23%));
        //    text-decoration: underline;
    }
}

.btn-danger-CC0000{
    #gradient > .vertical(@start-color: @red_CC0000; @end-color: lighten(@red_CC0000, 13%));
    &:hover {
        #gradient > .vertical(@start-color: @red_CC0000; @end-color: lighten(@red_CC0000, 23%));
        //    text-decoration: underline;
    }
}

.btn-danger-FF6600{
  #gradient > .vertical(@start-color: @red_FF6600; @end-color: lighten(@red_FF6600, 13%));
  &:hover {
    #gradient > .vertical(@start-color: @red_FF6600; @end-color: lighten(@red_FF6600, 23%));
    //    text-decoration: underline;
  }
}

.btn-primary {
    #gradient > .vertical(@start-color: @btn-primary-bg; @end-color: lighten(@btn-primary-bg, 13%));
    &:hover {
        #gradient > .vertical(@start-color: @btn-primary-bg; @end-color: lighten(@btn-primary-bg, 23%));
        //    text-decoration: underline;
    }
}

.btn-warning {
    .glyphicon {
        color: white;
    }
    #gradient > .vertical(@start-color: @btn-warning-bg; @end-color: lighten(@btn-warning-bg, 15%));
    &:hover {
        #gradient > .vertical(@start-color: @btn-warning-bg; @end-color: lighten(@btn-warning-bg, 23%));
        //    text-decoration: underline;
    }
}

.btn-danger {
    #gradient > .vertical(@start-color: @btn-danger-bg; @end-color: lighten(@btn-danger-bg, 15%));
    &:hover {
        #gradient > .vertical(@start-color: @btn-danger-bg; @end-color: lighten(@btn-danger-bg, 23%));
        //    text-decoration: underline;
    }
}

.btn-default {
    #gradient > .vertical(@start-color: @btn-default-bg; @end-color: lighten(@btn-default-bg, 12%));
    &:hover {
        #gradient > .vertical(@start-color: @btn-default-bg; @end-color: lighten(@btn-default-bg, 53%));
        //    text-decoration: underline;
    }
}

.btn-info {
    #gradient > .vertical(@start-color: @btn-info-bg; @end-color: lighten(@btn-info-bg, 16%));
    &:hover {
        #gradient > .vertical(@start-color: @btn-info-bg; @end-color: lighten(@btn-info-bg, 23%));
        //    text-decoration: underline;
    }
}
