h1, h2, h3, h4 {
    font-family: MPC;
    //border-bottom: 1px solid #aaa;
    color: #3D4E60;
    a {
        text-decoration: underline;
    }

}

@media (max-width: @screen-xs-max) {
    h1 {font-size: 30px;}
    h2 {font-size: 25px;}
    h3 {font-size: 21px;}
    h4 {font-size: 17px;}
}

/******* оформление для контента страниц - статейки всякие и тп *****/
.page-content {
    .main-text-style();
    padding-right: 0;
    padding-bottom: 20px;

    @media (max-width: @screen-xs-max) {
        // телефоны
        /* default :) */
    }
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
        // таблетки
        padding-right: 0;
    }
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
        // средние десктопы
        padding-right: 20px;
    }
    @media (min-width: @screen-lg) {
        // большие десктопы
        padding-right: 20px;
    }

    figure {
        padding: 13px;
        background-image: url("/images/aside-bg.jpg");
        margin-bottom: 20px;
        a {
            text-align: center;
        }
        img {
            margin-bottom: 10px;
            display: inline-block;
            position: relative;
            left: 0;
        }
        figcaption {
            text-align: center;
            padding: 10px;
            font-style: italic;
        }
    }

    img {
        .img-thumbnail();
        .img-responsive();
    }

    ul {
        list-style-type: none;
        padding: 20px 20px 10px 30px;
        margin: 0px;

        li {
            background-image: url("/images/bullets/square-green.gif");
            background-repeat: no-repeat;
            background-position: 0px 10px;
            padding: 0 0 10px 20px;
        }
        /* li */

    }
    /* ul */
}

.popover {
    h3 {
        font-family: inherit;
    }
}


/* .page-content */

.panel-heading {
    h1, h2, h3, h4 {
        border-bottom: none;
    }
}

.no-bullets {
    list-style-type: none;
}

.glyphicon.icon-bukviza {
    text-align: center;
    width: 60px;
    font-size: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: white;
    opacity: 0.5;
    background-color: #b3afa2;
    line-height: 30px;
    padding: 10px;
    float: left;
}

a {
    color: #3D4E60;
}

.main-text-style {
    line-height: 22px;
    font-size: 13px;
    //color: #555;
    color: #000;
    a {
        text-decoration: underline;
    }
}

.page-text {
    .main-text-style();
    margin-right: 20px;
    table {
        .nice-table();
    }
    @media (max-width: @screen-xs-max) {
        // телефоны
        margin-right: 0;
    }
}

.glyphicon {
    color: #3D4E60;
}

.smallo-tron {
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    line-height: 28px;
    background-color: #e6e4db;
    font-size: 18px;
    padding: 20px;
    border: 1px solid #cad2d5;
    border-radius: 10px;
}


.panel {
    h3 {
        margin: 0;
        font-family: inherit;
        font-size: 16px;
    }
    &.panel-primary {
        h3 {
            color: white;
        }
    }
}

.nav-pills {
    li {
        text-align: center;
        a {
            color: rgba(104, 104, 104, 0.87);
            background-color: #e6e4dd;
            &:hover {
                background-color: #d1cdc0;
            }
        }
        &.active {
            a {
                //				background-color: #d1cdc0;
            }
        }
    }
}

.header_link {
    border-bottom: 2px solid #8e9fb4;
    a {
        text-decoration: none !important;
        color : #739b42;
        &:hover {
            text-decoration: underline;
        }
        .arrow_append();
    }
}

.well {
    background-color: #e6e4db;
    padding: 20px;
    border: 1px solid #cad2d5;
    border-radius: 5px;
    box-shadow: inset -1px -2px 2px #f0eee5, 1px 1px 0 #e6e4dd;
    h1, h2, h3, h4 {
        margin-top: 0;
    }
    @media (max-width: @screen-xs-max) {
        // телефоны
        padding: 10px;
    }
    &.well-white {
        background-color: #f6f6f6;
        box-shadow: none;
    }
}

.product_thumbnail {
    background: white;
    border: 1px solid #cad2d5;
    border-radius: 5px;
    overflow: hidden;
    padding: 4px;

    /*
        вписать картинку в рамку.
        чтобы рамки были одинаковыми - нужно сделать style="width: xx px; height: xx px;"
        no IE support :(
        read: https://medium.com/@chrisnager/center-and-crop-images-with-a-single-line-of-css-ad140d5b4a87
        demo: http://codepen.io/chrisnager/pen/XJRRwo?editors=110
    */
    object-fit: contain;
}

.intro_box {
    .well();
    .clearfix();
    //background-color: #f1f0ec;
    background-color: #ffffff;
    padding: 8px;
    margin-bottom: 10px;
    .text {
        margin-top: 8px;
    }
    .season {
        font-size: 110%;
        font-weight: bold;
        margin: 7px 0 0 0;
    }
    .reviews {
        color: #739b42;
    }
}

.man-best {
    .well();
    background: #f1f0ec url("/images/background-manufacturers-best.png") right bottom no-repeat;
    text-align: center;
}


.stars {
    width: 75px;
    height: 14px;
    background: url("/images/stars.png") 0 0 no-repeat;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 3px;
    div {
        width: 75px;
        height: 14px;
        background: url("/images/stars.png") 0 0 no-repeat;
        overflow: hidden;
        background-position: -75px 0;
    }
}

// сноска с иконками. например в карточке модели шины
.footnote {
    font-size: 12px;
    text-align: right;
    color: #6d85a5;
    .glyphicon {
        font-size: 15px;
    }
}

table form {
    margin: 0;
}

aside.note {
    .well();
    font-size: 85%;
    color: #83827f;
    .compressed {
        margin: 5px 0;
    }
    .intro {
        color: #AA0000;
    }
}


ul.pdf {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    li {
        background-image: url(/i/icons/pdf.gif);
        background-repeat: no-repeat;
        background-position: 0px 5px;
        padding-left: 26px;
        padding-top: 3px;
    }
}

hr {
    border-top: 1px solid #aaa;
}