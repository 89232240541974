#carousel-main-page {
  img {
    width: 100%;
  }
  .carousel-indicators {
    bottom: auto;
    top: 20px;
    left: auto;
    right: 10%;
    width: auto;
  }
} /* #carousel-main-page */

.category-list {
  margin-top: 20px;
  margin-right: 20px;
  h3 {
    border-bottom: 0;
  }
  h3 a {
    display: inline-block;
    height: 48px;
    font-size: 22px;
    line-height: 22px;
    color: @green !important;
    padding-left: 21px;
    border-bottom: 0;
//    text-decoration: none;
  }
  ul {
    margin: 0 0 20px 0;
    padding: 0 20px;
    list-style: none;
    display: block;
    overflow: hidden;
    a {
      display: block;
      font-size: 15px;
      line-height: 16px;
      text-decoration: underline;
      padding-left: 20px;
      margin-bottom: 10px;
      background: url("/images/bullets/square-green.gif") 0 8px no-repeat;
      &:hover {
        text-decoration: none;
      }
    }
  } /* ul */
} /* .category-list */