// странные стили для картинок старого автобама
.descr-image {
  //  margin: 10px;
  display: inline-block;
  background-color: #f1f0ec;
  padding: 10px;


  img, .fancybox img {
    border: solid #cad2d5 7px;
    box-sizing: content-box;
  }

  &.autobam_float_left {
    float: left;
  }
  &.autobam_float_right {
    float: right;
  }
  &.autobam_above_left {

  }

  &.autobam_table_right {
    float: right;
  }
}