/*.cart-mini-cleaner{
  height: 62px;
}


.cart-mini{
//Для эксперимента Тест банера покров по переходу в контакты (#884)
  @media (max-width: 1655px){
    margin-left: 0px;
    margin-right: 0px;
  }
  // --------------------------
  @media (min-width: @screen-sm) and (max-width: @screen-sm-max) { // таблетки
    //width: 750px;
  }
  @media (min-width: @screen-md) and (max-width: @screen-md-max) { // средние десктопы
    //width: 970px;
  }
  @media (min-width: @screen-lg) { // большие десктопы
    //width: 1170px;
  }
  // TODO: width by media query;
  height: 62px;
  font-size:12px;
  text-align:center;
  position:fixed;
  z-index:1031;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  background-color:#f6f3f0;
  border-bottom: 1px solid black;
  #gradient > .vertical(@start-color: @bgColor; @end-color: darken(@bgColor, 7%));

  div, form {
    display:inline-block;
  }

  .inner{
    padding:5px 30px 5px 35px;
    margin:15px 30px;
    background:url("/images/icons/cart-mini.png") left top no-repeat;
  }

  .goto{
    text-decoration:underline;
    :hover{text-decoration:none;}
  }


} /* cart-mini */

/*.cart-mini.float-top{
  .opacity(0.7);
  #gradient > .vertical(@start-color: @bgColor; @end-color: #eee7e1);
  border-bottom: 1px solid black;

  :hover{
    .opacity(1);
  }
} /* .cart-mini.float-top */

/*.cart-mini.float-top:hover{
  .opacity(1);

} /* .cart-mini.float-top:hover */

/*.cart-mini.short {
  width:100px;
  height:180px;
  text-align:center;
  margin:15px 0 0 0;
  float:right;
  background-color:@bgColor;
  border-bottom: 0;

  #gradient > .vertical(@start-color: @bgColor; @end-color: #eee7e1);

  -webkit-border-top-right-radius:5px;
  -moz-border-radius-topright:5px;
  border-top-right-radius:5px;
  -webkit-border-bottom-right-radius:5px;
  -moz-border-radius-bottomright:5px;
  border-bottom-right-radius:5px;

  div{display:block;}

  .inner{
    color:#4b5f7a !important;
    padding:50px 10px 15px 10px;
    margin:15px 0 0 0;
    background-position:center top;

    .goto{
      display:block;
      margin-bottom:10px;
    }

    button{margin-top:20px;}
  }
} /* cart-mini.short */

.cart-mini-cleaner{
  height: 62px;
}


.cart-mini{
  //Для эксперимента Тест банера покров по переходу в контакты (#884)
  @media (max-width: 1655px){
    margin-left: 0px;
    margin-right: 0px;
  }
  // TODO: width by media query;
  height: 62px;
  font-size:12px;
  text-align:center;
  position:fixed;
  z-index:1031;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  background-color:#f6f3f0;
  border-bottom: 1px solid black;
  #gradient > .vertical(@start-color: @bgColor; @end-color: darken(@bgColor, 7%));

  div, form {
    display:inline-block;
  }

  .inner{
    padding:5px 30px 5px 35px;
    margin:15px 30px;
    background:url("/images/icons/cart-mini.png") left top no-repeat;
  }

  .goto{
    text-decoration:underline;
    :hover{text-decoration:none;}
  }

  @media (max-width: @screen-sm-max) { // таблетки
    .inner{
      padding:5px 10px 5px 35px;
      margin:15px 5px;
    }
  }
} /* cart-mini */

.cart-mini.float-top{
  .opacity(0.9);
  #gradient > .vertical(@start-color: @bgColor; @end-color: #eee7e1);
  border-bottom: 1px solid black;
  bottom: -1px;

  :hover{
    .opacity(1);
  }
} /* .cart-mini.float-top */

.cart-mini.float-top:hover{
  .opacity(1);

} /* .cart-mini.float-top:hover */

.cart-mini.short {
  width:100px;
  height:180px;
  text-align:center;
  margin:15px 0 0 0;
  float:right;
  background-color:@bgColor;
  border-bottom: 0;

  #gradient > .vertical(@start-color: @bgColor; @end-color: #eee7e1);

  -webkit-border-top-right-radius:5px;
  -moz-border-radius-topright:5px;
  border-top-right-radius:5px;
  -webkit-border-bottom-right-radius:5px;
  -moz-border-radius-bottomright:5px;
  border-bottom-right-radius:5px;

  div{display:block;}

  .inner{
    color:#4b5f7a !important;
    padding:50px 10px 15px 10px;
    margin:15px 0 0 0;
    background-position:center top;

    .goto{
      display:block;
      margin-bottom:10px;
    }

    button{margin-top:20px;}
  }
} /* cart-mini.short */