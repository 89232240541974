
.pagination, .paging {
    text-align: center;
    margin: 20px 0;
    display: block;
    ul {
        list-style-type: none;
        padding: 0;
        display: inline-block;
        //border-radius: 5px;
        li {
            display: inline;
            a {
                font-size: 16px;
                float: left;
                padding: 0 18px;
                line-height: 32px;
                text-decoration: none;
                background-color: #ffffff;
                border: 1px solid #dddddd;
                color: #739b42;
                margin: 1px 0;

                //				border-left-width: 0;
                margin-left: -1px; // хитрожопый прием чтобы убрать двойные бордеры между элементами
                &.active {
                    color: #cad2d5;
                    background-color: #f5f5f5;
                }
                &:hover {
                    //					color: #cad2d5;
                    background-color: #f5f5f5;
                }
            }
            &:last-child a {
                border-radius: 0 5px 5px 0;
            }
            &:first-child a {
                border-radius: 5px 0 0 5px;
            }
        }
    }
}