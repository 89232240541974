body {
    margin: 0;
    font-family: "Trebuchet MS", Tahoma, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 14px;
    color: #000;
    background-color: #cad2d5;


    // remove h-scrolling потому-что баннер-покров мать его!
    max-width: 100%;
    overflow-x: hidden;
}

#wrapper {
    position: relative;
}

//Для эксперимента Тест банера покров по переходу в контакты (#884)
@media (max-width: 1655px) {
    .container, .cart-mini {
        margin-left: 0;
        margin-right: 0;
    }
}

// -----------------

.container {
    background-color: @bgColor;

    @media (max-width: @screen-xs-max) {
        header, footer, .page-content-wrapper {
            margin-left: -15px;
            margin-right: -10px;
        }
    }

    .page-content-wrapper {
        padding: 0 15px;
        @media (min-width: @screen-sm) {
            // таблетки и ширее
            padding: 0;
        }
    }
}

.page_header {
    color: #4b5f7a !important;
    padding-top: 20px;

    .contacts-link {
        text-align: center;
        padding-bottom: 18px;

        a {
            display: inline-block;
            font-size: 1.4em;
            text-decoration: none;

            &:first-child {
                margin-right: 40px;
            }

            span {

                font-size: 0.9em;
            }

            strong {
                font-weight: normal;
                text-decoration: underline;
            }
        }
    }

    .logo {
        text-align: right;

        @media(min-width: 990px) {
            height: 10px;
        }

        @media (max-width: @screen-md-max) {
            // средние десктопы и ниже
            text-align: center;
        }

        .logo_header {
            display: inline-block;
            width: 266px; //213
            height: 150px; //74
            //margin-left: 25px;
            background: url(/images/logo.png) center right no-repeat;

            @media (max-width: @screen-md-max) {
                // средние десктопы и ниже
                width: 213px; //213
                height: 150px; //74
            }

            @media (max-width: 990px) {
                // средние десктопы и ниже
                height: 74px; //74
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .motto {
        height: 50px;
        font-size: 15px;
        margin-left: 70px;

        ul {
            display: inline;
            padding: 0;
            margin: 0;
            list-style: none;
            overflow: visible;

            li {
                display: inline-block;
                overflow: hidden;
                width: 45px;
                text-align: center;
                vertical-align: middle;

            }

            li.last {
                width: auto;
                text-align: left;
                padding-left: 5px;
                padding-bottom: 15px;
            }
        }
        /* ul */

        span {
            display: inline-block;
            min-width: 10px;
            color: #515151 !important;
            text-align: center;
            text-shadow: 0 0 2px #ffffff;
            border: 1px solid #cad2d5;
            padding: 7px 4px;
            margin-right: 1px;
            background-color: #f8f7f6;
            #gradient > .vertical(@start-color: #fcfcfc; @end-color: #f1f0ec);
        }
        /* span */

        small {
            font-size: 9px;
            line-height: 10px;
            color: #444444 !important;
        }
        /* small */

    }
    /* motto */

    .address_plate {
        position: relative;
        min-height: 60px;
        font-size: 17px;
        line-height: 22px;
        text-align: center;
        padding: 19px 0 0 68px;
        width: 698px;
        margin-top: 8px;
        background: url("/images/header-background.svg") 100% 50% no-repeat;
    }
    @media (min-width: 1920px) {
        .address_plate {
            margin-bottom: 15px;
        }
    }

    .sert_header_link a {
        width: 25%;
        height: 50px;
        float: right;
        top: -40px;
        display: block;
        position: relative;
    }
    /* address */

    button.showMapBox {
        #gradient > .vertical(@start-color: @green; @end-color: lighten(@green, 13%));
        padding: 2px 4px 2px 8px;
        border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
        height: 24px;
        margin-left: 2px;
    }

    a.showMapBox {
        padding-right: 8px;
        text-decoration: none;
        border-bottom: 1px #112766 dashed;
    }

    #modalMapBox {
        font-size: 13px;
        text-align: left;
        padding: 10px 20px;
        border-top: 1px solid #4b5f7a;

        .modal-dialog {
            width: 660px;

            .modal-content {

                //text-align: center;
                border: 1px solid #4b5f7a;
                background: @bgColor;
                //padding: 12px;

                .modal-body {
                    padding: 20px;

                    .address {
                        a {
                            display: block;
                            width: 150px;
                            font-size: 16px;
                            color: @green;
                            text-decoration: underline;
                            margin-bottom: 2px;
                            float: left;
                        }
                        address {
                            margin-bottom: 0;
                        }
                    }

                    #map {
                        position: relative;
                        border: solid 1px black;
                        width: 600px;
                        height: 300px;

                        .loader {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 140px;
                            height: 2em;
                            margin: -1em 0 0 -70px;
                            text-align: center;
                        }
                    }

                    ul {
                        display: inline;
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        overflow: visible;
                        li {
                            display: block;
                            overflow: hidden;
                            color: #4b5f7a !important;
                            margin-bottom: 10px;
                            line-height: 18px;
                        }
                    }
                    .address_modal_header {
                        line-height: 18px;
                        color: #ffffff !important;
                        padding: 4px 30px 7px 20px;
                        background: url("/images/block-arrow.png") right top no-repeat;
                        display: block;
                        text-align: center;
                        font-size: 14px;
                        margin: 10px 0 30px 0;
                        border-bottom: none;
                    }
                    .ymaps-b-select_control_traffic {
                        position: absolute;
                        right: -120px;
                    }
                    .ymaps-copyrights-pane {
                        display: none;
                    }
                }
                /* modal-body */
            }
            /* modal-content */

            .modal-footer {
                a.btn .glyphicon {
                    color: white;
                }
            }
        }
        /* modal-dialog */
    }
    /* #modalMapBox */

    .payment-cards {
        height: 30px;
        text-align: right;
        text-decoration: underline;

        a {
            display: inline-block;
            text-decoration: underline;
            margin: 4px 3px 0 0;

            &:hover {
                text-decoration: none;
            }
        }

        img {
            overflow: visible;
            margin-left: 2px;
            float: right;
            border: 1px solid #4b5f7a;
        }

        &:hover {
            text-decoration: none;
        }

        @media (max-width: @screen-xs-max) {
            // телефоны

        }
        @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
            // таблетки
            padding-right: 40px;
            margin-top: 5px;
        }
        @media (min-width: @screen-md) and (max-width: @screen-md-max) {
            // средние десктопы
            padding-right: 17px;
        }
        @media (min-width: @screen-lg) {
            // большие десктопы
            padding-right: 35px;
        }

    }
    /* payment-cards */

}

.navbar-default {
    z-index: 1000;
    color: #8c9fb8;
    text-shadow: 0 1px 1px #4b5f7a;
    background-color: #586e8b;
    #gradient > .vertical(@start-color: #4b5f7a; @end-color: #6d85a5);
    border-color: #6d85a5 #6d85a5 #4b5f7a;
    border-bottom: 3px solid #41536a;
    margin-bottom: 20px;
    min-height: inherit;
    border-radius: 0;

    .navbar-brand, .navbar-brand:hover {
        color: white;
    }

    .collapse {
        padding: 0;
    }
    .navbar-toggle .icon-bar {
        background-color: #d5d5d5;
    }

    .navbar-nav {
        margin: 0;
        li {
            padding: 4px 6px 2px 6px;
            margin: 0;
            border-right: 1px solid #41536a;

            @media(min-width: 1200px){
                padding: 4px 7px 2px 7px;
            }

            &.open {
                background-color: #4b5f7a !important;
            }

            &.last {
                border: 0 solid red;
            }
            span.caret {
                display: none;
            }
            &.open a {
                background: transparent !important;
                color: white !important;
                &:focus {
                    color: white;
                }
            }
            a {
                padding: 0;
                color: white;
                &:hover {
                    color: white;
                    text-shadow: 0 0 10px #ffffff;
                    //background: transparent;
                }
            }
        }

        .dropdown-menu {
            margin-top: 1px;
            border-radius: 0 0 6px 6px;
            background: #4b5f7a;

            li {
                padding: 0 15px !important;
                border-right: 0 !important;

                a, p {
                    display: block;
                    padding: 8px 5px;
                    margin: 0 !important;
                    border-bottom: 1px solid #38465a;
                    border-top: 1px solid #556b8a;
                    font-size: 13px;
                }

                &:first-child > a {
                    border-top: 0;
                }

                &:last-child > a {
                    border-bottom: 0;
                }

                big {
                    padding: 0;
                    margin: 0;

                    a {
                        font-size: 14px;
                        color: #fbfbfc !important;
                        border: 0;
                        font-weight: bold;
                    }
                }

                ul { /* третий уровень навигационного меню */
                    display: inline;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    overflow: visible;
                    li {
                        padding-right: 0 !important;
                        width: 180px;
                        display: block;
                        overflow: hidden;
                    }
                }
                /* /ul */

            }
            /* /li */
        }
        /* /.dropdown-menu */
    }
    /* .navbar-nav */

    .navbar-form {
        padding-right: 15px;
        margin: 4px 0 0 0;
        &.navbar-right:last-child {
            margin-right: 0;
        }
        .form-control {
            width: 180px;
            height: 21px;
            font-size: 11px;
            //color: #cad2d5 !important;
            padding: 4px 10px 2px 30px;
            background: #ffffff url("/images/icons/magnifier.png") 5% 50% no-repeat;
            border-radius: 15px;
            @media (min-width: @screen-md) and (max-width: @screen-md-max) {
                // средние десктопы
                width: 130px;
            }
        }
    }
    /* form */

}

.search_form {
    width: 180px;
    height: 21px;
    font-size: 11px;
    //color: #cad2d5 !important;
    padding: 4px 10px 2px 30px;
    background: #ffffff url("/images/icons/magnifier.png") 5% 50% no-repeat;
    border-radius: 15px;
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
        // средние десктопы
        width: 130px;
    }
}

/* .navbar-default */

.breadcrumbs {
    background: url("/images/icons/breadcrumbs.png") 0px 0px no-repeat transparent;
    border-radius: 0px;
    font-size: 13px;
    margin-top: 3px;
    padding: 4px 0px 2px 27px;
    a {
        color: #999 !important;
    }
    img {
        margin: -6px 2px 0px 4px;
    }
    ul {
        display: inline;
        list-style: none outside none;
        margin: 0px;
        overflow: visible;
        padding: 0px;
        li {
            display: inline-block;
            overflow: hidden;
            &.active {
                color: #999;
            }
        }
    }
    /* ul */
}

/* breadcrumbs */

#page-footer {
    //width: 940px;
    color: #4b5f7a !important;
    padding: 30px 30px 15px 30px;
    margin: 20px auto 0 auto;
    background: #f1f0ec;
    border-top: 1px dashed #cad2d5;
    overflow: hidden;
    strong {
        opacity: 0.7;
    }
    .address {
        h3 {
            margin-top: 0;
            @media (max-width: @screen-sm-max) {
                // телефоны
                margin-top: 20px;
            }
        }
        .social_links {
            a {
                width: inherit;
                margin-top: 10px;
                margin-right: 5px;
            }
            img {
                width: 30px;
                opacity: 0.5;
            }
        }
        a, span {
            display: block;
            float: left;
        }
        a {
            font-size: 1.4rem;
            width: 130px;
            color: @green !important;
            text-decoration: none !important;
            &:hover {
                text-decoration: underline !important;
            }
        }
        ul {
            display: inline;
            padding: 0;
            margin: 0;
            list-style: none;
            overflow: visible;
            line-height: 2.2rem;
            li {
                display: block;
                overflow: hidden;
                border-bottom: 1px dashed #d8d5c9;
            }
        }
        /* ul */
    }
    /* .address */
}

/* #page-footer */

#aside_wrapper {
    position: absolute;
    top: 0px;
    right: -248px;

    #help_pokrov {
        position: relative;
        width: 244px;
        height: 204px;
        border: 1px solid #868686;
        background-color: #fff;
        z-index: 1000;
        margin-bottom: 4px;

        > a {
            display: block;
            text-decoration: none;

            &:not([href^="javascript"]){
                width: 242px;
                height: 202px;
                background: #fff url(/images/pokrov.jpg) left top no-repeat;
            }

            &[href^="javascript"]{
                position: absolute;
                right: 2px;
                top: 1px;
                width: 1.4em;
                height: 1.2em;
                //background-color: red;
            }

        }
    }

    #instagram_sale {
        position: relative;
        width: 244px;
        height: 204px;
        border: 1px solid #868686;
        background-color: #fff;
        z-index: 1000;
        overflow: hidden;

        .instagram_sale_text {
            color: #ce3277;
            font-size: 16px;
            font-weight: bold;
            padding: 10px 4px 4px;
            text-align: center;
        }

        > a {
            display: block;
            text-decoration: none;

            &:not([href^="javascript"]){
                width: 242px;
                height: 202px;
                background: #fff url(/images/instagram.png) left top no-repeat;
                background-position: center;
                background-size: 146px;
                background-position-y: -24px;
            }

            &[href^="javascript"]{
                position: absolute;
                right: 2px;
                top: 1px;
                width: 1.4em;
                height: 1.2em;
            }

        }
    }
}


    .webcam_header {
        margin-top: 0;
        color: #112766;
        font-family: MPC;
        border-bottom: 1px solid #aaa;
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: 500;
        line-height: 1.1;
    }