/* LOGO + TOP*/
.motto_new li {
  line-height: 4px;
}

.motto_new .last {
  font-size: 14px;
  padding-top: 9px;
}

.page_header .logo .logo_header {
  top: -12px !important;
}

.accordion-group,
.accordion-inner,
.nav-left .accordion .accordion-body,
.page-content-wrapper,
#wrapper,
tr.subheader th,
tr.darker_row td {
  background: #ffffff !important;
  color: #000000 !important;
}

@media (min-width: 1920px) {
  #wrapper {
    width: 73%;
  }
}

.nav-left .accordion .accordion-body,
.nav-left .accordion .accordion-heading a.collapsed,
.container .page-content-wrapper
.nav-left .accordion .accordion-heading .accordion-toggle,
.nav-left .accordion ul li a .inner,
ul.services-list li a,
.category-list > div > div > ul > li > a {
  color: #000000 !important;
}

.nav-left .accordion .accordion-heading a,
.nav-left .accordion ul li a:hover,
.nav-left .accordion ul li.active a {
  background-size: 0 0 !important;
}

table td {
  vertical-align: middle !important;
  border: 1px dashed #cccccc !important;
}







.page_header .address_plate_new {
  //background: url(/images/header-background-dark.svg) no-repeat;
  background: url('/images/header-background-dark-v2.svg') no-repeat;
  -moz-background-size: 100%; /* Firefox 3.6+ */
  -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
  -o-background-size: 100%; /* Opera 9.6+ */
  background-size: 100%; /* Современные браузеры */
  padding: 9px 0 0 158px;
  width: 100%;
  position: relative;
  .block-left {
    position: absolute;
    top: 18px;
    left: 8px;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
  }

  .block-right {
    position: absolute;
    top: 18px;
    right: 30px;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
  }
}

@media (max-width: 1199px) {
  .address_plate_new {
    .block-left {
      top: 14px !important;
      left: 7px !important;
      font-size: 10px !important;
    }
    .block-right {
      top: 14px !important;
      right: 25px !important;
      font-size: 10px !important;
    }
  }
}

div.accordion-group:not(first-child),
.accordion-inner li,
table tbody tr td {
  border-top: 1px dashed #cccccc;
}

tr.subheader th,
tr.darker_row td {
  border: 1px solid #cccccc !important;
}

tr.lighter_row td {
  border: none !important;
}