.height-alignment {
	height: auto;
}

.parts_show-more_btn {
	width: fit-content;
	margin: 0 auto;
	display: block;
}

.part-block_wrapper {
	width: 100%;
}

@media (min-width: 768px) {
	.part-block_wrapper {
		min-width: 307px;
	}
}

@media (min-width: 1200px) {
	.part-block_wrapper {
		min-width: 245px;
	}
}

.contacts_accordion {
	margin-top: 10px;
}
.ca_block {
	border: solid 1px #818181;
	border-radius: 10px;
	margin-bottom: 6px;
}
.ca_title {
	padding: 5px;
	padding-left: 10px;
	cursor: pointer;
	font-size: 14px;
}
.ca_content {
	margin-left: 10px;
	margin-right: 10px;
	overflow: hidden;
	height: 0px;
	transition: 0.3s;
}
.ca_content-elem p {
	margin-bottom: 0px;
}
.ca_content_link {
	margin-top: 5px;
	margin-bottom: 5px;
	display: block;
	max-width: 100px;
}

.header-numbers_wrapper {
	max-width: 170px;
}
.header-hidden_number-wrapper {
	max-width: 150px;
	display: inline-block;
}
.header-number_hiding-block {
	position: absolute;
	cursor: pointer;
	width: 149px;
	height: 20px;
	top: 1px;
}
.header-number_hiding-elem {
	font-size: 17px;
	line-height: 22px;
	background-color: #f4f4f4;
	height: 18px;
	margin-top: -2px;
	margin-left: auto;
	margin-right: 0px;
	width: 83px;
}

.modalPhonesWrapper {
	z-index: 101043;
	background-color: #ffffff;
	color: #333333;
	height: 0px;
	margin: 0 auto;
	display: block;
	overflow: hidden;
	transition: 0.3s;
}
.modalPhonesWrapperOpen {
	height: 256px;
}
.modalPhonesContent {
	padding: 10px;
}

.contacts_accordion {
	margin-top: 10px;
}
.ca_block {
	background-color: rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	margin-bottom: 10px;
}
.ca_title {
	display: flex;
	padding: 8px;
	padding-left: 10px;
	cursor: pointer;
	font-size: 18px;
}
.ca_arrow {
	background-image: url("/images/icons/down-arrow.png");
	background-size: contain;
	background-position-y: 2px;
	margin-left: auto;
	width: 20px;
	height: 20px;
}
.ca_arrow_up {
	background-image: url("/images/icons/up-arrow.png");
}
.ca_content {
	margin-left: 10px;
	margin-right: 10px;
	overflow: hidden;
	height: 0px;
	transition: 0.3s;
}
.ca_content-elem p {
	margin-bottom: 0px;
}
.ca_content_link {
	margin-top: 5px;
	margin-bottom: 5px;
	display: block;
	max-width: 100px;
}

/* ---- AB test numbers in header --- ---- */
.dop-numbers_wrapper-mt15 {
	margin-top: -15px!important;
}
.address_plate_new-mh {
	min-height: 85px!important;
}
.block-green-buttons-mt {
	margin-top: 12px;
}
.header-time_we-worked-opacity {
	opacity: 0;
}
/* ---- AB test numbers in header end ---- */
