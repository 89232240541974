.nice-table {
    width: 100%;
    font-size: 13px;
    border: 1px solid #ffffff;
    border-collapse: separate;
    border-radius: 8px;
    .text-left {
        text-align: left;
    }
    th {
        line-height: 18px;
        background-color: #3D4E60;
        border-bottom: 0 !important;
        color: #fff;
        font-weight: normal;
        a {
            color: #fff;
        }
        .glyphicon {
            color: inherit;
        }
    }

    tr:first-child {
        th:first-child, td:first-child {
            border-top-left-radius: 8px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 8px;
        }
    }

    tr:last-child {
        th:first-child, td:first-child {
            border-bottom-left-radius: 8px;
        }
        th:last-child, td:last-child {
            border-bottom-right-radius: 8px;
        }
    }

    thead {
        tr:last-child {
            th:first-child, td:first-child {
                border-bottom-left-radius: 0;
            }
            th:last-child, td:last-child {
                border-bottom-right-radius: 0;
            }
        }
    }

    tbody {

        tr:first-child {
            th:first-child, td:first-child {
                border-top-left-radius: 0;
            }
            th:last-child, td:last-child {
                border-top-right-radius: 0;
            }
        }

	  tr{
		td{

		  &.price{

			text-align: right !important;
		  }
		  &.price123{

			text-align: left !important;
		  }
		}
	  }

    }

    td, th {
        line-height: inherit;
        border-top: 1px solid #fff;
        vertical-align: bottom;
        padding: 8px;
    }
    th {
        text-align: center;
    }
    td {
        /*border-top: 1px solid #c8c8c8;*/
        text-align: center;
    }
    td:first-child {
        text-align: left;
    }
    .dimmed, .dimmed a {
        color: #aaaaaa;
    }
    .subheader {
        /* подзаголовки */
        td, th {
            background-color: #d8dedd;
            a {
                color: #3D4E60;
            }
        }
        h4 {
            a {
                .arrow_append();
            }
            margin: 0;
            padding-top: 6px;
            border: 0;
        }
    }
    tbody {
        tr:nth-child(odd) {
            background-color: #e6e4de;
        }
    }
    .darker_row {
        background-color: #e6e4de !important;
    }
    .lighter_row {
        //background-color: #f1f0ec !important;
        background-color: #ffffff !important;
    }
    .extra_head {
        font-weight: bold;
        font-style: italic;
        color: #555555;
    }

  .pointer{
	cursor: pointer;
  }
}