#contacts_page {
    .nav-pills {
        li {
            margin-left: 0!important;
            margin-bottom: 10px;
        }
    }
    .tab-content.pills-content {
        min-height: 0;
        margin-top: 10px;
        border: 0;
        box-shadow: none;
    }
    .rekvisity {
        margin: 20px 0;
    }
    .map {
        margin: 20px 0;
    }

    @media (max-width: @screen-xs-max) {
        // телефоны
        .nav-tabs-left-container {
            width: 43px;
        }

        .nav-tabs-left-tabs {
            width: 100%;
        }

        .tabs_expand_on_mobiles {
            display: block!important;
            margin-top: 10px;
        }
    }

}