.our-works {

  .search-form {
    background:#f1f0ec;
    border:1px solid #cad2d5;
    border-radius:5px;
    box-shadow:inset -3px -3px 3px #f5f5f2, 1px 1px 0 #e6e4dd;
    overflow:hidden;
    padding:15px;
    margin-bottom: 20px;
    .search-btn {
      text-align: center;
    }
    .btn {
      margin-top: 7px;
    }
  }
}