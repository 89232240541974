.search_by_car {
  .kreplenie {
    font-size: 14px;
    line-height: 20px;
  }
  .search-btn {
    float: right;
    /*top: -1px;
    position: relative;*/
  }
  a.list-group-item {
    text-decoration: none;
  }
  .panel-body p {
    font-size: 13px;
  }
}