
.comments {
    article {
        border-bottom: 1px solid #c4c1b0;
        padding: 10px 0 5px 0;
        &:last-child {
            margin-bottom: 20px;
        }
        h4 {
            color: #848484;
            border: 0;
            margin-bottom: 4px;
            padding: 0;
        }
        .stars {
            float: left;
            margin: 2px 5px 0 0;
        }
        .date {
            font-weight: normal;
            font-size: 14px;
        }
    }
}