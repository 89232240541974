.media {
    background-color: #E6E4DD;
    padding: 10px;
    border: 1px solid white;

    &:hover {
        background-color: #D1CDC0;
    }

    .media-object {
        margin-right: 10px;
        border: 1px solid #b6b6b6;
        min-width: 100px;
        background-color: white;
        text-align: center;
        padding: 1px;
    }
    a {
        text-decoration: none;
        .media-body {
            text-decoration: none;
            span {
                color: lightgray;
            }
            b {
                color: #a50700;
            }
        }
    }
}