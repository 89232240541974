.left_menu {
  max-width: 300px;
}

.nav-left {
  .left-menu-header {
    margin: 0px;
    background: url("/images/block-arrow.png") 100% 0% no-repeat transparent;
    color: #FFFFFF !important;
    font-size: 14px  !important;
    line-height: 18px;
    padding: 5px 20px 6px 31px;
  } /* left-menu-header */

  .accordion{ /* общее оформление аккордиона - фон, рамка, отступы */
    background: #F1F0EC;
    border: 1px solid #FFFFFF;
    border-top: 0;
    margin: 0px 15px 20px 0px;

    .accordion-heading { /* заголовки первого уровня */

      .accordion-toggle { /* общее оформление ссылки */
        cursor: pointer;
        display: block;
        .inner {
          display: block;
          padding-left: 14px;
        }
      }

      &:hover { /* наведение мыши на заголовок */
        background-color: #D1CDC0;
      }




      a { /* стрелочки на заговлоках при наведении мыша */
        margin: 0 10px 0 8px;
        background: url("/images/arrows/down-green.png") 0 10px no-repeat ;
        &.collapsed {
          background: none;
          &:hover { /* наведение мыши на свернутый заголовок */
            background: url("/images/arrows/down-green.png") 0 10px no-repeat;
          }
        }

        &:hover { /* наведение мыши на развернутый заголовок */
          background: url("/images/arrows/up-green.png") 0 10px no-repeat;
        }

        &:not(.collapsed) {
          &.active { /* текущий (активный) заголовок */
            background: url("/images/arrows/down-green.png") 8px 10px no-repeat #D1CDC0;
            &:hover {
              background-image: url("/images/arrows/up-green.png");
            }
          } /* active */
        } /* &:not(.collapsed)  */
      } /* a */

    }/* accordion-heading */

    .accordion-body { /* блок второго уровня */
      background: #E6E4DD;
      padding-bottom: 5px;
    } /* accordion-body */

    a { /* общее оформление ссылок */
      display: block;
      margin: 0px 15px;
      text-decoration: none !important;
      text-shadow: 1px 1px 1px #FFFFFF;
      color: #555 !important;
      padding: 6px 0px;
    }

    ul {
      display: inline;
      list-style: disc outside;
      margin: 0px;
      overflow: visible;
      padding: 0px;
      li {
        display: block;
        overflow: hidden;
        //margin-left: 20px;
        &.active a, {
          background:transparent url("/images/arrows/right-green.png") 0 10px no-repeat;
        }
        a {
          margin-left: 20px;
          .inner {
            display: block;
            padding-left: 15px;
          }
          li.active a, &:hover {
            background:transparent url("/images/arrows/right-green.png") 0 10px no-repeat;
          }
        }
        &.active, &:hover {
          background: #D1CDC0;

          a b {
            width:10px;
            height:7px;
            margin-bottom:2px;
            background:transparent url("/images/arrows/right-green.png") center top no-repeat;
          }
        }
      }
    }/* ul */

  } /* accordion */
} /* nav-left */