.service-category {
    .main-text-style();
    padding-right: 20px;
    h3 {

    }
    .descr {
        margin-left: 37px;
        a {
            text-decoration: none;
            color: #555;
            &:hover {
                text-decoration: underline;
            }

        }
    }
}

.services-category {
    .services-list {
        //    opacity: 0.9;
    }
    .short_description {
        a {
            text-decoration: none;
            color: #43596a;

        }
        line-height: 17px;
        font-size: 13px;
    }
}


.services-service {
    .parts {
        h3:first-child {
            margin-top: 10px;
        }
    }
    .nice-table {
        .inner_col {
            vertical-align: middle;
            border-left: solid #ddd0a9 1px;
            text-align: center;
        }
        .extra td {
            padding-bottom: 30px;
            .btn-primary {
                .glyphicon {
                    color: white;
                }
            }
            &:last-child {
                text-align: left;
            }
        }
    }
}


.services {
    .thumbnail {
        padding: 13px;
        img {
            float: right;
            margin: 0 0 10px 10px;
        }
        .alert {
            margin-bottom: 10px;
        }
        .btn {
            margin-top: 0;
        }
        .alert {
            padding: 8px 15px;
            text-align: center;
        }
        .btn-primary {
            .arrow_append();
            text-decoration: none;
            display: block;
            margin: 0 auto;
            width: 150px;
        }
    }
}